<template>
<v-app>

<!-- SIDEBAR -->
<v-navigation-drawer
  v-model="drawer"
  app
  clipped
>
  <v-sheet
    color="grey lighten-4"
    class="pa-4"
  >
    <v-avatar
      class="mb-4"
      color="grey darken-1"
      size="64"
    >
    <img
        src="pt.png"
        alt="Philipp"
      >
  </v-avatar>

    <!-- <div style="color: gray">philipp@omnibox.me</div> -->
  </v-sheet>

  <v-divider></v-divider>

  <!-- EMAIL FOLDERS -->
  <v-list>
    <v-list-item
      v-for="[icon, text] in links"
      :key="icon"
      link
    >
      <v-list-item-icon>
        <v-icon>{{ icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ text }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!-- AUTHORIZATION -->
    <v-btn id="authorize_button" v-show="!authorized" @click="googleSignIn()" class="ml-4 mt-4">Authorize</v-btn>
    <v-btn id="signout_button" v-show="authorized" @click="googleSignOut()" class="ml-4 mt-4">Sign Out</v-btn>

  </v-list>

  <!-- MIC BUTTON -->
  <v-fab-transition>
    <v-btn
      color="pink"
      dark
      absolute
      bottom
      right
      fab
      class="mb-10"
      @click="recognitionStart"
    >
      <v-icon>{{micStatus}}</v-icon>
    </v-btn>
  </v-fab-transition>

</v-navigation-drawer>

<!-- APP BAR -->
<v-app-bar app clipped-left>
  <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

  <v-toolbar-title>
    <!-- <span>
      <v-img src="./assets/logo.png" height="30px" width="30px"></v-img>
    </span> -->
    </v-toolbar-title>
</v-app-bar>

<!-- MAIN CONTAINER -->
<v-main>
  <v-container
    class="py-8 px-6"
    fluid
  >
  <!-- EMAIL -->
  <v-row>
    <v-col
      v-for="card in cards"
      :key="card"
      cols="12"
    >
      <v-card>
        <v-subheader>{{ card }}</v-subheader>

        <v-list two-line>
          <v-list-item-group
          v-model="selected"
          active-class="pink--text"
          >
          <template v-for="(item, index) in items">
            <v-list-item :key="item.id">
              <template v-slot:default="{ active }">
                <!-- EMAIL -->
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>

                  <v-list-item-subtitle
                    class="text--primary"
                    v-text="item.headline"
                  ></v-list-item-subtitle>

                  <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                </v-list-item-content>

                <!-- EMAIL ACTIONS -->
                <v-list-item-action>
                  <v-list-item-action-text>{{ $dayjs(item.date).format('DD/MM/YYYY') }}</v-list-item-action-text>

                  <v-icon
                    v-if="!active"
                    color="grey lighten-1"
                    >
                    mdi-star-outline
                  </v-icon>

                  <v-icon
                    v-else
                    color="yellow darken-3"
                  >
                    mdi-star
                  </v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-divider
              v-if="index < items.length - 1"
              :key="index"
            ></v-divider>

          </template>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>
  </v-row>

  <!-- ACTION BAR -->
  <v-row>
    <v-col>
      <v-card
        class="mx-auto"
        max-width="290"
      >
        <v-card-text class="center">
          <v-form ref="form">
            <span><v-text-field
              v-model="action"
              ></v-text-field></span><span id="interim"></span>

              <div id="results">
                <span id="final_span" class="final" v-html="final_transcript">At your service...</span>
                <span id="interim_span" class="interim" v-html="interim_transcript"></span>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
    </v-col>
  </v-row>

  <v-row>
    <v-col>
      <p class="output"></p>
    </v-col>
  </v-row>

  <!-- TOASTER -->
  <v-snackbar
    v-model="snackbarShow"
    :timeout="snackbarTimeout"
  >
    {{ snackbarText }}

    <template v-slot:action="{ attrs }">
      <v-btn
        color="blue"
        text
        v-bind="attrs"
        @click="snackbarShow = false"
      >
      Close
    </v-btn>
    </template>
  </v-snackbar>

  </v-container>

</v-main>

<!-- FOOTER -->
<v-footer
  padless
>
  <v-row
    justify="center"
    no-gutters
  >
    <v-btn
      v-for="link in bottomLinks"
      :key="link"
      color="white"
      text
      rounded
      class="my-2"
    >
      {{ link }}
    </v-btn>
  </v-row>
</v-footer>

</v-app>
</template>

<script>
import '@/plugins/Dayjs'

export default {
  data: () => ({
    // Client ID and API key from the Developer Console
    CLIENT_ID: '1008404676275-6q9lqnbo8uflfns95fe9tih0h1ekbc3m.apps.googleusercontent.com',
    API_KEY: 'AIzaSyA_rsTHHQ0WdUlj3oIhu4QSj6vzIGrWsLg',

    // Array of API discovery doc URLs for APIs used by the quickstart
    DISCOVERY_DOCS: ["https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest"],

    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    SCOPES: 'https://www.googleapis.com/auth/gmail.readonly',
    authorized: false,
    cards: ['Today'],
    drawer: null,
    actionBar: true,
    action: 'At your service...',
    micStatus: 'mdi-microphone',
    final_transcript: "",
    interim_transcript: "",
    links: [
      ['mdi-inbox-arrow-down', 'Inbox'],
      ['mdi-send', 'Send'],
      ['mdi-delete', 'Trash'],
      ['mdi-alert-octagon', 'Spam']
    ],
    bottomLinks: [
      'Inbox', 'News', 'Outfox', 'Calendar', 'Other'
    ],
    selected: 0,
    items: [],
    snackbarShow: false,
    snackbarText: '',
    snackbarTimeout: 2000,
    recognition: null
  }),
  methods: {
    /**
     *  Is the date today in local time?
     *  Source: https://flaviocopes.com/how-to-determine-date-is-today-javascript/
     */
    isToday (someDate) {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
          someDate.getMonth() == today.getMonth() &&
          someDate.getFullYear() == today.getFullYear()
    },
    formatDate (date) {
      return date.toLocaleDateString()
    },
    /**
    *  Initializes the API client library and sets up sign-in state
    *  listeners.
    */
    initGAPI () {

      /**
      *  Called when the signed in status changes, to update the UI
      *  appropriately. After a sign-in, the API is called.
      */
      const updateSigninStatus = (isSignedIn) => {
        if (isSignedIn) {
          this.authorized = true
          this.listMessages()
          // listLabels()
        } else {
          this.authorized = false
        }
      }

      window.gapi.client.init({
        apiKey: this.API_KEY,
        clientId: this.CLIENT_ID,
        discoveryDocs: this.DISCOVERY_DOCS,
        scope: this.SCOPES
      }).then(() => {
        // Listen for sign-in state changes.
        window.gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus)

        // Handle the initial sign-in state.
        updateSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get())
      }, (error) => {
        console.log(error, null, 2)
      })
    },

    /**
    *  Sign in the user upon button click.
    */
    googleSignIn () {
      window.gapi.auth2.getAuthInstance().signIn(
        // {
        //   ux_mode: 'redirect'
        // }
      )
    },

    /**
    *  Sign out the user upon button click.
    */
    googleSignOut () {
      window.gapi.auth2.getAuthInstance().signOut()
    },

    /**
    * Print all Labels in the authorized user's inbox. If no labels
    * are found an appropriate message is printed.
    */
    // const listLabels = () => {
    //   window.gapi.client.gmail.users.labels.list({
    //     'userId': 'me'
    //   }).then(function(response) {
    //     var labels = response.result.labels
    //     console.log('Labels:')
    //
    //     if (labels && labels.length > 0) {
    //       for (let i = 0; i < labels.length; i++) {
    //         var label = labels[i]
    //         console.log(label)
    //       }
    //     } else {
    //       console.log('No Labels found.')
    //     }
    //   })
    // }

    listMessages () {
      window.gapi.client.gmail.users.messages.list({
        'userId': 'me',
        'maxResults': 10,
        'labelIds': ['INBOX']
      }).then(response => {
        var messages = response.result.messages

        if (messages && messages.length > 0) {
          for (let i = 0; i < messages.length; i++) {
            var message = messages[i]
            this.getMessage(message.id)
          }
        } else {
          console.log('No Messages found.')
        }
      })
    },
    getMessage (id) {
      window.gapi.client.gmail.users.messages.get({
        'userId': 'me',
        'id': id
      }).then((response) => {
        // let msgTo = response.result.payload.headers.find(header => header.name === 'To')
        let msgFrom = response.result.payload.headers.find(header => header.name === 'From')
        let msgSubject = response.result.payload.headers.find(header => header.name === 'Subject')
        // console.log(msgTo.value)
        //
        this.items.push({ date: new Date(parseInt(response.result.internalDate)),
          headline: msgFrom.value,
          subtitle: response.result.snippet,
          title: msgSubject.value,
          id: response.result.id})

          console.log(response.result)
        })
      },
      deleteItem() {
        // console.log(this.selected)
        // console.log(typeof(this.selected))
        let selectedTmp = this.selected
        // console.log(selectedTmp)
        // console.log(typeof(selectedTmp))
        this.items.splice(this.selected, 1)

        // if (selectedTmp == this.items.length) {
        //   selectedTmp--
        //   console.log(selectedTmp)
        //   console.log(typeof(selectedTmp))
        // }
        //
        this.selected = [selectedTmp]
        // console.log(this.selected)
        // console.log(typeof(this.selected))
      },
      recognitionStart () {
        this.recognition.start()
        console.log('Ready to receive a command.')
      },
      recognitionOn () {
        // var commands = [ 'aqua' , 'azure' , 'beige', 'bisque', 'black', 'blue', 'brown', 'chocolate', 'coral', 'crimson', 'cyan', 'fuchsia', 'ghostwhite', 'gold', 'goldenrod', 'gray', 'green', 'indigo', 'ivory', 'khaki', 'lavender', 'lime', 'linen', 'magenta', 'maroon', 'moccasin', 'navy', 'olive', 'orange', 'orchid', 'peru', 'pink', 'plum', 'purple', 'red', 'salmon', 'sienna', 'silver', 'snow', 'tan', 'teal', 'thistle', 'tomato', 'turquoise', 'violet', 'white', 'yellow']

        this.recognition = new window.webkitSpeechRecognition()
        var SpeechRecognitionEvent = SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent
        this.recognition.continuous = true
        this.recognition.lang = 'en-US'
        this.recognition.interimResults = true
        this.recognition.maxAlternatives = 1

        var diagnostic = document.querySelector('.output')

        this.recognitionStart()

        // document.body.onclick = function() {
        //   recognition.start()
        //   console.log('Ready to receive a command.')
        // }

        this.recognition.onresult = (event) => {
          // The SpeechRecognitionEvent results property returns a SpeechRecognitionResultList object
          // The SpeechRecognitionResultList object contains SpeechRecognitionResult objects.
          // It has a getter so it can be accessed like an array
          // The first [0] returns the SpeechRecognitionResult at the last position.
          // Each SpeechRecognitionResult object contains SpeechRecognitionAlternative objects that contain individual results.
          // These also have getters so they can be accessed like arrays.
          // The second [0] returns the SpeechRecognitionAlternative at position 0.
          // We then return the transcript property of the SpeechRecognitionAlternative object
          //
          //
          console.log("received")

          this.interim_transcript = ''
          for (var i = event.resultIndex; i < event.results.length; ++i) {
            if (event.results[i].isFinal) {
              console.log(event.results[i][0].transcript)
              this.final_transcript = event.results[i][0].transcript.trim()
              let command = this.final_transcript
              // diagnostic.textContent = 'Command: ' + command + '.'
              if (command == 'delete' || command == 'deleted' || command == 'done' || command == 'Delete') {
                console.log("Delete")
                console.log(command)
                this.deleteItem()
              }
              else if (command == 'not important') {
                this.snackbarShow = true
                this.snackbarText = "Moved to Other"
                console.log("Moved to Other")
                console.log(command)
                this.deleteItem()
              }
              else if (command == 'unsubscribe' || command == 'no thank you' || command == 'Unsubscribe') {
                this.snackbarShow = true
                this.snackbarText = "Unsubscribe request sent"
                console.log("Unsubscribe request sent")
                console.log(command)
                this.deleteItem()
              }
              console.log('Transcript: ' + command)
              // console.log('Confidence: ' + result.confidence)
            } else {
              console.log(event.results[i][0].transcript)
              this.interim_transcript = event.results[i][0].transcript
            }
          }


          let result = event.results[event.results.length-1][0]
          if (result.isFinal) {
            let command = result.transcript.trim()
            diagnostic.textContent = 'Command: ' + command + '.'
            if (command == 'delete' || command == 'deleted' || command == 'done' ) {
              console.log("Delete")
              console.log(command)
              this.deleteItem()
            }
            // else if (command == 'not important') {
            //   this.snackbarShow = true
            //   this.snackbarText = "Moved to Other"
            //   console.log("Moved to Other")
            //   console.log(command)
            //   this.deleteItem()
            // }
            // else if (command == 'unsubscribe') {
            //   this.snackbarShow = true
            //   this.snackbarText = "Unsubscribe request sent"
            //   console.log("Unsubscribe request sent")
            //   console.log(command)
            //   this.deleteItem()
            // }
            console.log('Transcript: ' + command)
            // console.log('Confidence: ' + result.confidence)
          }
        }

        this.recognition.onspeechend = () => {
          console.log("Speech end")
          this.micStatus = 'mdi-microphone-off'
          this.recognition.stop()
        }


        this.recognition.onnomatch = () => {
          diagnostic.textContent = "I didn't recognise that command."
        }

        this.recognition.onerror = (event) => {
          if (event.error == "no-speech") {
            return
          }
          else {
            diagnostic.textContent = 'Error occurred in recognition: ' + event.error
          }
        }
      },
      init() {
        /**
        *  On load, called to load the auth2 library and API client library.
        */
        window.gapi.load('client:auth2', this.initGAPI)

        /**
        * Turn on speech recognition
        */
        this.recognitionOn()
      }
    },
    mounted() {
      this.init()
    }
  }
  </script>

  <style  lang="scss" scoped>

    .final {
      padding-right: 3px;
    }
    .interim {
      color: gray;
    }

  </style>
