var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-navigation-drawer',{attrs:{"app":"","clipped":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-sheet',{staticClass:"pa-4",attrs:{"color":"grey lighten-4"}},[_c('v-avatar',{staticClass:"mb-4",attrs:{"color":"grey darken-1","size":"64"}},[_c('img',{attrs:{"src":"pt.png","alt":"Philipp"}})])],1),_c('v-divider'),_c('v-list',[_vm._l((_vm.links),function(ref){
var icon = ref[0];
var text = ref[1];
return _c('v-list-item',{key:icon,attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)],1)}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.authorized),expression:"!authorized"}],staticClass:"ml-4 mt-4",attrs:{"id":"authorize_button"},on:{"click":function($event){return _vm.googleSignIn()}}},[_vm._v("Authorize")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.authorized),expression:"authorized"}],staticClass:"ml-4 mt-4",attrs:{"id":"signout_button"},on:{"click":function($event){return _vm.googleSignOut()}}},[_vm._v("Sign Out")])],2),_c('v-fab-transition',[_c('v-btn',{staticClass:"mb-10",attrs:{"color":"pink","dark":"","absolute":"","bottom":"","right":"","fab":""},on:{"click":_vm.recognitionStart}},[_c('v-icon',[_vm._v(_vm._s(_vm.micStatus))])],1)],1)],1),_c('v-app-bar',{attrs:{"app":"","clipped-left":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title')],1),_c('v-main',[_c('v-container',{staticClass:"py-8 px-6",attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.cards),function(card){return _c('v-col',{key:card,attrs:{"cols":"12"}},[_c('v-card',[_c('v-subheader',[_vm._v(_vm._s(card))]),_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item-group',{attrs:{"active-class":"pink--text"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._l((_vm.items),function(item,index){return [_c('v-list-item',{key:item.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}}),_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(item.headline)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.subtitle)}})],1),_c('v-list-item-action',[_c('v-list-item-action-text',[_vm._v(_vm._s(_vm.$dayjs(item.date).format('DD/MM/YYYY')))]),(!active)?_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v(" mdi-star-outline ")]):_c('v-icon',{attrs:{"color":"yellow darken-3"}},[_vm._v(" mdi-star ")])],1)]}}],null,true)}),(index < _vm.items.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2)],1)],1)],1)}),1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"290"}},[_c('v-card-text',{staticClass:"center"},[_c('v-form',{ref:"form"},[_c('span',[_c('v-text-field',{model:{value:(_vm.action),callback:function ($$v) {_vm.action=$$v},expression:"action"}})],1),_c('span',{attrs:{"id":"interim"}}),_c('div',{attrs:{"id":"results"}},[_c('span',{staticClass:"final",attrs:{"id":"final_span"},domProps:{"innerHTML":_vm._s(_vm.final_transcript)}},[_vm._v("At your service...")]),_c('span',{staticClass:"interim",attrs:{"id":"interim_span"},domProps:{"innerHTML":_vm._s(_vm.interim_transcript)}})])])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('p',{staticClass:"output"})])],1),_c('v-snackbar',{attrs:{"timeout":_vm.snackbarTimeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbarShow = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbarShow),callback:function ($$v) {_vm.snackbarShow=$$v},expression:"snackbarShow"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)],1),_c('v-footer',{attrs:{"padless":""}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},_vm._l((_vm.bottomLinks),function(link){return _c('v-btn',{key:link,staticClass:"my-2",attrs:{"color":"white","text":"","rounded":""}},[_vm._v(" "+_vm._s(link)+" ")])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }